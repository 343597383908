






















































































import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Announcement } from '@/types/announcement'
import GroupComponent from '@/components/Announcement/AnnouncementGroup.vue'

@Component({
  components: {
    GroupComponent,
  },
})
export default class extends Vue {
  private announcements: Partial<Announcement>[] = []
  private addDialog = false
  private fileName = ''
  private groupName = ''
  private description = ''
  private url = ''
  private requireFunc = (val: string) => val.length > 0 || '*Require'
  private onAdd = false

  mounted() {
    this.fetchAnnouncements()
  }

  private get announcementGroup() {
    const res = this.announcements.reduce<
      Array<{
        group: string
        data: Partial<Announcement>[]
      }>
    >((prev, curr) => {
      const group = curr.group || 'No Group'
      const index = prev.findIndex(e => e.group === group)
      if (index < 0) {
        return [...prev, { group: group, data: [curr] }]
      } else {
        return prev.map((e, i) => {
          if (i === index) {
            e.data = [...e.data, curr]
          }
          return e
        })
      }
    }, [])
    return res.sort((a, b) => {
      if (a.group.toLowerCase() > b.group.toLowerCase()) {
        return 1
      } else {
        return -1
      }
    })
  }

  private closeDialog() {
    this.addDialog = false
    this.fileName = ''
    this.groupName = ''
    this.description = ''
    this.url = ''
  }

  private async fetchAnnouncements() {
    try {
      const res = await this.$apollo.query<{
        announcements: Partial<Announcement>[]
      }>({
        query: gql`
          query FetchAnnouncements {
            announcements {
              id
              name
              group
              description
              url
              fileSizes
            }
          }
        `,
      })
      if (res.data) {
        this.announcements = res.data.announcements
      }
    } catch (error) {
      // empty
    }
  }

  private async addNewAnnouncement() {
    this.onAdd = true
    try {
      const data: {
        [key: string]: string
      } = {
        name: this.fileName,
      }
      if (this.groupName !== '') {
        data['group'] = this.groupName
      }
      if (this.description !== '') {
        data['description'] = this.description
      }
      if (this.url !== '') {
        data['url'] = this.url
      }
      const res = await this.$apollo.mutate<{
        createAnnouncement: Partial<Announcement>
      }>({
        mutation: gql`
          mutation AddNewAnnouncement($data: CreateAnnouncementMutation!) {
            createAnnouncement(data: $data) {
              id
              name
              group
              description
              url
              fileSizes
            }
          }
        `,
        variables: {
          data: data,
        },
      })
      if (res.data && res.data.createAnnouncement) {
        this.announcements.push(res.data.createAnnouncement)
      }
    } catch (error) {
      // empty
    } finally {
      this.closeDialog()
      this.onAdd = false
    }
  }

  private updateData(e: Partial<Announcement>) {
    this.announcements = this.announcements.map(el => (el.id === e.id ? e : el))
  }

  private deleteData(e: number) {
    this.announcements.splice(
      this.announcements.findIndex(el => el.id === e),
      1
    )
  }
}
