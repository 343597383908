






















































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Announcement } from '@/types/announcement'
import gql from 'graphql-tag'
import { vxm } from '@/store'

@Component
export default class extends Vue {
  @Prop() readonly data!: Partial<Announcement>
  private editDialog = false
  private name = ''
  private desc = ''
  private url = ''
  private group = ''
  private onEdit = false
  private deleteDialog = false
  private onDelete = false
  private imageDialog = false
  private imageUrl = ''
  private fetchingImage = false
  private uploadDialog = false
  private onUpload = false
  private fileForUpload: File[] = []

  private get description() {
    return this.data.description || '-'
  }

  private get dataUrl() {
    return this.data.url || '-'
  }

  private get files() {
    return this.data.fileSizes || []
  }

  private setupEditForm() {
    this.name = this.data.name || ''
    this.desc = this.data.description || ''
    this.url = this.data.url || ''
    this.group = this.data.group || ''
  }

  private closeDialog() {
    this.editDialog = false
    this.name = ''
    this.desc = ''
    this.url = ''
    this.group = ''
  }

  private async editData() {
    this.onEdit = true
    try {
      if (this.data.id) {
        const id = this.data.id
        const body: { [key: string]: string } = {
          name: this.name,
        }
        if (this.desc !== '') {
          body['description'] = this.desc
        }
        if (this.url !== '') {
          body['url'] = this.desc
        }
        if (this.group !== '') {
          body['group'] = this.group
        }
        const { data } = await this.$apollo.mutate<{
          editAnnouncement: Partial<Announcement>
        }>({
          mutation: gql`
            mutation EditAnnouncement(
              $data: EditAnnouncementMutation!
              $id: Int!
            ) {
              editAnnouncement(data: $data, id: $id) {
                id
                name
                group
                description
                url
                fileSizes
              }
            }
          `,
          variables: {
            data: body,
            id: id,
          },
        })
        if (data) {
          this.$emit('editAnnouncement', data.editAnnouncement)
        }
      }
    } catch (error) {
      // empty
    } finally {
      this.closeDialog()
      this.onEdit = false
    }
  }

  private async deleteData() {
    console.log('delete')
    this.onDelete = true
    try {
      if (this.data.id) {
        const { data } = await this.$apollo.mutate<{
          deleteAnnouncement: { deletedId: number }
        }>({
          mutation: gql`
            mutation DeleteAnnouncement($id: Int!) {
              deleteAnnouncement(id: $id) {
                deletedId
              }
            }
          `,
          variables: {
            id: this.data.id,
          },
        })
        if (data) {
          this.$emit('deleteAnnouncement', data.deleteAnnouncement.deletedId)
        }
      }
    } catch (error) {
      // empty
    } finally {
      this.deleteDialog = false
      this.onDelete = false
    }
  }

  private async fetchImage(size: string) {
    this.fetchingImage = true
    this.imageDialog = true
    const [width, height] = size.split('x')
    try {
      if (this.data.id) {
        const { data } = await this.$apollo.query<{
          announcement: Partial<Announcement>
        }>({
          query: gql`
            query FetchImage($id: Int!, $width: Int!, $height: Int!) {
              announcement(id: $id) {
                id
                file(width: $width, height: $height) {
                  contentType
                  signedUrl
                }
              }
            }
          `,
          variables: {
            id: this.data.id,
            width: +width,
            height: +height,
          },
        })
        if (data) {
          this.imageUrl = data.announcement.file?.signedUrl || ''
        }
      }
    } catch (error) {
      // error
    } finally {
      this.fetchingImage = false
    }
  }

  private async deleteImage(size: string) {
    if (this.data.id && window.confirm('Delete file?')) {
      const [width, height] = size.split('x')
      try {
        const { data } = await this.$apollo.mutate<{
          deleteAnnouncementWithFileName: Partial<Announcement>
        }>({
          mutation: gql`
            mutation DeleteAnnouncementFile(
              $data: DeleteAnnounceByFileSizeMutation!
            ) {
              deleteAnnouncementWithFileName(data: $data) {
                id
                name
                group
                description
                url
                fileSizes
              }
            }
          `,
          variables: {
            data: {
              id: this.data.id,
              width: +width,
              height: +height,
            },
          },
        })
        if (data) {
          this.$emit('editAnnouncement', data.deleteAnnouncementWithFileName)
        }
      } catch (error) {
        // empty
      }
    }
  }

  private async uploadFiles() {
    this.onUpload = true
    try {
      const forms = new FormData()
      for (const i of this.fileForUpload) {
        forms.append('files', i)
      }
      const res = await this.axios.post(
        `${process.env.VUE_APP_OFFICE_URL}/announcement/${this.data.id}/upload`,
        forms,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${vxm.login.getToken}`,
          },
        }
      )
      if (res.status === 201) {
        try {
          const { data } = await this.$apollo.query<{
            announcement: Partial<Announcement>
          }>({
            query: gql`
              query FetchChangeAnnouncementData($id: Int!) {
                announcement(id: $id) {
                  id
                  name
                  group
                  description
                  url
                  fileSizes
                }
              }
            `,
            variables: {
              id: this.data.id,
            },
          })
          if (data) {
            this.$emit('editAnnouncement', data.announcement)
          }
        } catch (error) {
          // empty
        }
      }
    } catch (error) {
      // empty
    } finally {
      this.uploadDialog = false
      this.onUpload = false
      this.fileForUpload = []
    }
  }
}
