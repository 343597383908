















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Announcement } from '@/types/announcement'
import List from './AnnouncementList.vue'

@Component({
  components: {
    List,
  },
})
export default class extends Vue {
  @Prop() readonly announcementGroup!: Array<{
    group: string
    data: Partial<Announcement>[]
  }>

  private update(e: Partial<Announcement>) {
    this.$emit('updateAnnouncement', e)
  }

  private deleteData(e: number) {
    this.$emit('deleteAnnouncement', e)
  }
}
